import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'redux/hooks';

import { areDatesLessThanDayApart, formatDate, formatCountryInLocale, getTypeMode } from 'lib/helpers';

import { Date } from 'types/EventTypes';
import { Venue } from 'types/EditionTypes';

import { AppLink, Link } from 'components/Link';
import { GoToNextPageButton } from 'components/GoToNextPageButton';
import { ShareModal } from 'components/modals/ShareModal';
import { ChangeEditionModal } from 'components/modals/ChangeEditionModal';
import { Icon } from 'components/Icon';
import { DropDown } from 'components/DropDown';
import { trackLinkClick } from 'hooks/useGA';

interface ChannelInfoProps {
    lang: string | undefined;
    cartQuantity: number | undefined;
    showOnMobile: boolean;
    previewToken: string | undefined;
    timeLeft: number;
    seatmapActive: boolean;
}

export const ChannelInfo: React.FC<ChannelInfoProps> = (props) => {
    const { lang, cartQuantity, showOnMobile, previewToken, timeLeft, seatmapActive } = props;
    const { event, edition, cart, iframe } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
            cart: state.store.cart,
            iframe: state.store.iframe,
        };
    });

    if (!event || !edition || !cart || iframe) return null;

    const { t } = useTranslation();

    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [editionToChangeTo, setEditionToChangeTo] = useState<string | undefined>(undefined);

    const changeEdition = (editionUri: string) => {
        if (editionUri === edition.uri) return;
        setEditionToChangeTo(editionUri);
    };

    const introduction = edition.channel.introduction ?? edition.introduction;

    const typeMode = getTypeMode(edition);

    const handleShareModal = () => {
        trackLinkClick('share', 'channel_info_icon');
        setShowShareModal(true);
    };

    return (
        <>
            <StyledChannelInfo showOnMobile={showOnMobile} seatmapActive={seatmapActive}>
                <div className={`sticky ${previewToken ? 'preview' : ''}`}>
                    <EditionTitle>
                        <h1>{event.name}</h1>
                        {event.editions.length <= 1 && edition.name && <h2>{edition.name}</h2>}
                        {event.editions.length > 1 && (
                            <>
                                {' '}
                                <DropDown
                                    align="left"
                                    onChange={changeEdition}
                                    options={event.editions.map((edition) => ({
                                        label: edition.name,
                                        value: edition.uri,
                                    }))}
                                    value={edition.uri}
                                >
                                    <h2>
                                        {edition.name}
                                        <span className="inline-icon">
                                            <Icon name="arrow-down-s-line" />
                                        </span>
                                    </h2>
                                </DropDown>
                                <ChangeEditionModal
                                    event={event}
                                    newEditionUri={editionToChangeTo}
                                    unsetNewEditionUri={() => setEditionToChangeTo(undefined)}
                                />
                            </>
                        )}
                    </EditionTitle>

                    <EditionDetail date={edition.date} venue={edition.venue} lang={lang} />
                    {introduction && <EditionIntroduction>{introduction}</EditionIntroduction>}
                    {(edition.description || edition.venue) && (
                        <EditionInfoLink>
                            <Link
                                to="/info"
                                onClick={() => {
                                    trackLinkClick('info', 'channel_info_full');
                                }}
                            >
                                {t('channel_info.more_information')}
                            </Link>
                        </EditionInfoLink>
                    )}
                    <StyledActionIcons>
                        <StyledActionIcon onClick={handleShareModal}>
                            <Icon name="share-box-fill" alt={t('channel_info.icon_share')} />
                        </StyledActionIcon>
                        {(edition.description || edition.venue) && (
                            <AppLink
                                to="/info"
                                onClick={() => trackLinkClick('info', 'channel_info_icon')}
                                className="channel_info_icon"
                            >
                                <StyledActionIcon>
                                    <Icon name="information-line" alt={t('channel_info.icon_info')} />
                                </StyledActionIcon>
                            </AppLink>
                        )}
                        <AppLink
                            to="/support"
                            onClick={() => trackLinkClick('help', 'channel_info_icon')}
                            className="channel_info_icon"
                        >
                            <StyledActionIcon>
                                <Icon name="questionnaire-line" alt={t('channel_info.icon_support')} />
                            </StyledActionIcon>
                        </AppLink>
                    </StyledActionIcons>
                    <StyledGoToNextPageButtonWrapper>
                        <GoToNextPageButton
                            cartQuantity={cartQuantity}
                            fastLane={cart.fastlane_url}
                            previewToken={previewToken}
                            timeLeft={timeLeft}
                            channelUrl={edition.channel.url}
                            iframe={iframe}
                            typeMode={typeMode}
                        />
                    </StyledGoToNextPageButtonWrapper>
                </div>
            </StyledChannelInfo>
            <ShareModal
                shareLink={edition.channel.url}
                showModal={showShareModal}
                closeModal={() => setShowShareModal(false)}
            />
        </>
    );
};

interface EditionDetailProps {
    date: Date | null;
    venue: Venue | null;
    lang: string | undefined;
}

interface DetailDateProps {
    date: Date | null;
    lang: string | undefined;
}

const DetailDate: React.FC<DetailDateProps> = (props) => {
    const { date, lang } = props;
    if (!date || !date.start || !lang) return null;

    const firstLetterUppercase = (str: string | null) => {
        if (!str) return null;
        return str[0].toUpperCase() + str.slice(1);
    };

    const detailDateStart = date ? firstLetterUppercase(formatDate(date.start, date.hide_hours, lang)) : null;

    if (date.start && !date.end) {
        return <p>{detailDateStart}</p>;
    }

    if (date.start && date.end) {
        if (areDatesLessThanDayApart(date.start, date.end)) {
            return <p>{detailDateStart}</p>;
        }
        const detailDateEnd = date?.end ? firstLetterUppercase(formatDate(date.end, date.hide_hours, lang)) : null;
        return (
            <p>
                {detailDateStart} -<br />
                {detailDateEnd}
            </p>
        );
    }

    return null;
};

const EditionDetail: React.FC<EditionDetailProps> = (props) => {
    const { date, venue, lang } = props;
    const { t } = useTranslation();
    if (!date?.start && !venue) return null;

    const getDetailVenue = (venue: Venue | null) => {
        if (!venue) return null;

        if (venue.name && venue.city && venue.country_code) {
            return `${venue.name} - ${venue.city}, ${formatCountryInLocale(venue.country_code, lang)}`;
        }
        if (venue.name && venue.city) {
            return `${venue.name} - ${venue.city}`;
        }
        if (venue.name && venue.country_code) {
            return `${venue.name} - ${formatCountryInLocale(venue.country_code, lang)}`;
        }
        if (venue.name) {
            return venue.name;
        }
        if (venue.city && venue.country_code) {
            return `${venue.city}, ${formatCountryInLocale(venue.country_code, lang)}`;
        }
        if (venue.city) {
            return venue.city;
        }
        return null;
    };

    const detailVenue = getDetailVenue(venue);

    return (
        <StyledEditionDetail>
            <DetailDate date={date} lang={lang} />
            {detailVenue && (
                <p>
                    {detailVenue}{' '}
                    <StyledLocationIcon>
                        <AppLink to="/info">
                            <Icon name="map-pin-line" alt={t('channel_info.icon_map')} />
                        </AppLink>
                    </StyledLocationIcon>
                </p>
            )}
        </StyledEditionDetail>
    );
};

const StyledChannelInfo = styled.div<{ showOnMobile: boolean; seatmapActive: boolean }>`
    padding: 2.5rem;
    box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.fontLight)};
    display: block;

    div.sticky {
        position: sticky;
        top: 1.5rem;

        &.preview {
            top: 4.5rem;
        }
    }

    @media (max-width: 57em) {
        grid-row-start: 1;
        padding: 1.5rem;

        display: ${(props) => (props.showOnMobile ? 'block' : 'none')};
    }

    ${(props) =>
        props.seatmapActive &&
        css`
            display: none;
        `}
`;

const EditionTitle = styled.div`
    margin-bottom: 1rem;
    color: ${(props) => props.theme.colors.primary};

    h1 {
        font-weight: 700;
        font-size: 2rem;
    }

    h2 {
        font-weight: 600;
        font-size: 1.75rem;
        color: ${(props) => props.theme.colors.primary};

        span.inline-icon {
            display: inline-block;
            position: relative;
            bottom: -0.375rem;
        }
    }

    select {
        display: inline-block;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;
        border: none;
        //padding: 0 1em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        cursor: inherit;
        line-height: inherit;
        font-size: inherit;
        outline: none;

        font-weight: 600;
        font-size: 1.75rem;
        line-height: 1.75rem;
        color: ${(props) => props.theme.colors.primary};
        position: relative;

        &:active {
            font-size: 1rem;
        }
    }
`;

const StyledEditionDetail = styled.div`
    color: ${(props) => props.theme.colors.fontDark};
    font-size: 1rem;
    font-weight: 600;
    /* font-style: italic; */
    font-variation-settings: 'ital' 125;
    margin-bottom: 1rem;
    line-height: 1.2;
`;

const StyledLocationIcon = styled.span`
    display: inline-block;
    position: relative;
    bottom: -0.1875rem;
    margin-left: 0.125rem;

    a {
        color: ${(props) => props.theme.colors.fontLight};
        text-decoration: none;

        &:hover {
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;

const EditionIntroduction = styled.p`
    color: ${(props) => props.theme.colors.fontMedium};
    margin-bottom: 1rem;
    line-height: 1.2;
`;

const EditionInfoLink = styled.p`
    line-height: 1.2;
    margin-bottom: 1rem;
`;

const StyledActionIcons = styled.div`
    margin-bottom: 2rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    @media (max-width: 57em) {
        margin-bottom: 0;
    }

    div {
        margin-right: 0.75rem;
    }
`;

const StyledActionIcon = styled.div`
    color: ${(props) => props.theme.colors.fontLight};
    cursor: pointer;
    transition: color 200ms ease;

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;

const StyledGoToNextPageButtonWrapper = styled.div`
    @media (max-width: 57em) {
        display: none;
    }
`;
